import { Component } from '@angular/core';

@Component({
  selector: 'app-cf',
  templateUrl: './cf.component.html',
  styleUrls: ['./cf.component.css']
})
export class CfComponent {

}
