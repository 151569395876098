<section id="header">
  <div class="overlay"></div>
  <div class="container-fluid">
    <div class="container px-0">
      <nav
        class="navbar navbar-expand-xl"
        style="border-bottom: 2px solid white"
      >
        <a href="index.html" class="navbar-brand"
          ><img
            src="assets/img/network/logo.png"
            class="img-fluid"
            style="width: auto; height: 6rem"
        /></a>
        <button
          class="navbar-toggler py-2 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span class="fa fa-bars text-primary"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav d-lg-none">
            <a [routerLink]="['/']" class="nav-item nav-link">Home</a>
            <a [routerLink]="['/mission']" class="nav-item nav-link"
              >Philanthropy Mission</a
            >
            <a [routerLink]="['/pledge']" class="nav-item nav-link"
              >IAM Pledge</a
            >
            <a [routerLink]="['/about']" class="nav-item nav-link">About</a>
            <a [routerLink]="['/package']" class="nav-item nav-link">
              View Services</a
            >
            <a [routerLink]="['/ai']" class="nav-item nav-link">
              Additional Features</a
            >
          </div>
          <div class="d-flex m-3 ms-auto">
            <p class="text-white" style="margin: 2px 25px; font-size: 25px">
              UMYO
            </p>
            <button
              class="btn-search btn border border-white btn-md-square rounded-circle me-4"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <i class="fas fa-search text-white"></i>
            </button>
            <a href="#" class="position-relative me-4 my-auto">
              <i class="fa fa-shopping-cart text-white fa-2x"></i>
            </a>
            <a href="#" class="my-auto">
              <button class="btn btn-primary bg-white text-dark border-0 p-2">
                Get Started
              </button>
            </a>
          </div>
        </div>
      </nav>
      <nav class="navbar navbar-expand-lg d-lg-block d-none menu-nav2">
        <div class="container-fluid p-0">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/']">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/mission']"
                  >Philanthropy Mission</a
                >
              </li>
              <li class="nav-item">
                <a [routerLink]="['/pledge']" class="nav-link">IAM Pledge</a>
              </li>
              <li class="nav-item">
                <a [routerLink]="['/about']" class="nav-link">About</a>
              </li>
              <li class="nav-item">
                <a [routerLink]="['/package']" class="nav-link">
                  View Services</a
                >
              </li>
              <li class="nav-item">
                <a [routerLink]="['/ai']" class="nav-link"
                  >Additional Features</a
                >
              </li>

              <!-- 
<li class="nav-item dropdown">
<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
  Link Profile
</a>
<ul class="dropdown-menu">
  <li><a class="dropdown-item" href="#">Action</a></li>
  <li><a class="dropdown-item" href="#">Another action</a></li>
  <li><hr class="dropdown-divider"></li>
  <li><a class="dropdown-item" href="#">Something else here</a></li>
</ul>
</li> -->
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- Navbar End -->

  <!-- Modal Search Start -->
  <div
    class="modal fade"
    id="searchModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content rounded-0">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Search by keyword</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body d-flex align-items-center">
          <div class="input-group w-75 mx-auto d-flex">
            <input
              type="search"
              class="form-control p-3"
              placeholder="keywords"
              aria-describedby="search-icon-1"
            />
            <span id="search-icon-1" class="input-group-text p-3"
              ><i class="fa fa-search"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Search End -->

  <!-- Hero Start -->

  <div class="container-fluid hero-header mt-5">
    <div class="container py-5">
      <div class="row g-5 align-items-center">
        <div class="col-md-12 col-lg-7">
          <h1 class="mb-3 text-white">UMYO NETWORK</h1>
          <p class="text-white">
            Un-Masking Your Opinion - (United Movement for Youth Opportunities)
          </p>
          <a href="#">RE-DISCOVER "IAM" - "WE ARE" POSSIBILITIES</a>
        </div>
        <div class="col-md-12 col-lg-5 px-5">
          <div class="grid__item medium-up--one-half">
            <div class="videos__wrapper">
              <div class="videos__wrapper--video">
                <iframe
                  src="https://www.youtube.com/embed/XHOmBV4js_E"
                  title="Video Placeholder"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-5">
    <h1 class="text-center mb-5 text-white">
      THE UMYO NETWORK ALL IN ONE NETWORKING APP
    </h1>
    <div class="slider slider-1">
      <div class="mb-5">
        <img src="assets/img/network/logo2/blackwall.png" />
      </div>
      <div><img src="assets/img/network/logo2/events.png" /></div>
      <div><img src="assets/img/network/logo2/logo2.png" /></div>
      <div><img src="assets/img/network/logo2/logo3.png" /></div>
      <div><img src="assets/img/network/logo2/logo4.png" /></div>
      <div><img src="assets/img/network/logo2/logo5.png" /></div>
      <div><img src="assets/img/network/logo2/logo6.png" /></div>
      <div><img src="assets/img/network/logo2/logo7.png" /></div>
      <div><img src="assets/img/network/logo2/logo8.png" /></div>
      <div><img src="assets/img/network/logo2/logo9.png" /></div>
      <div><img src="assets/img/network/logo2/logo10.png" /></div>
    </div>

    <div class="slider slider-2 mb-4" dir="rtl">
      <div><img src="assets/img/network/logo2/logo11.png" /></div>
      <div class="mb-5"><img src="assets/img/network/logo2/logo12.png" /></div>
      <div><img src="assets/img/network/logo2/logo13.png" /></div>
      <div><img src="assets/img/network/logo2/logo14.png" /></div>
      <div><img src="assets/img/network/logo2/logo15.png" /></div>
      <div><img src="assets/img/network/logo2/logo16.png" /></div>
      <div><img src="assets/img/network/logo2/pledge.png" /></div>
      <div><img src="assets/img/network/logo2/logo9.png" /></div>
      <div><img src="assets/img/network/logo2/umyo_market.png" /></div>
      <div><img src="assets/img/network/logo2/logo8.png" /></div>
      <div><img src="assets/img/network/logo2/umyodate.png" /></div>
    </div>
  </div>
</section>

<!-- Hero End -->
<!-- Featurs Section Start -->
<section id="card_section">
  <div class="container-fluid featurs">
    <div class="container">
      <div class="row g-4 justify-content-center">
        <div class="col-lg-10 col-12 px-4">
          <div
            class="featurs-item rounded bg-white px-5 py-4 border-primary border-6 w-100"
            id="feature"
          >
            <div class="row">
              <div class="col-lg-3 col-12">
                <img src="assets/img/network/logo.png" class="img-fluid" />
              </div>
              <div class="col-lg-8 col-12">
                <h5 class="mb-3 text-dark">
                  THE ONLY NETWORK MEMBERSHIP WHERE COMMUNITY CAN NOW CONNECT
                  WITH OTHERS WHO SHARE SIMILAR INTERESTS.
                </h5>
                <p class="text-dark mb-0" style="font-size: 20px">
                  Three Network - One Registration
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!------- tab section start-------->

  <!------- tab section end ---------->
  <div class="container-fluid featurs pt-3 mt-3">
    <div class="container">
      <div class="row g-4">
        <div class="col-12">
          <h1 class="text-center">Single Application, Multiple Network</h1>
          <!-- <h1 class="text-center text-white w-75 display-1 p-2" style="   background: linear-gradient(269deg, rgba(0,87,206) 0%, rgba(0,87,206) 33%, rgba(0,87,206) 50%);margin: auto; ">#1 Networking Solution</h1> -->
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text2">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/1.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <!-- <a href="#" class="mt-5"><p class="mt-4 text-center">Register</p></a>             -->
        </div>

        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text5">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/2.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <h5 class="mt-4 text-center">THREE NETWORK IN ONE</h5>
          <!-- <a href="#" class="mt-5"><p class="mt-4 text-center">Register</p></a>  -->
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text1">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/3.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <!-- <a href="#" class="mt-5"><p class="mt-4 text-center">Register</p></a> -->
        </div>

        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text3">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/4.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-4">
            <li>
              <a href="../truckers.html#register" class="mt-5"
                >Truckers Tweet</a
              >
            </li>
            <li>
              <a href="../truckers.html#register" class="mt-5"
                >Truckers Directory</a
              >
            </li>
            <li>
              <a href="../truckers.html#register" class="mt-5">Truckers Talk</a>
            </li>
          </ul>
          <a href="../truckers.html#register" class="mt-5"
            ><p class="mt-4 text-center">Register</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/32.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-4">
            <li>
              <a href="../m_c.html#register" class="mt-5">Military Tweet</a>
            </li>
            <li>
              <a href="../m_c.html#register" class="mt-5">Military Directory</a>
            </li>
            <li>
              <a href="../m_c.html#register" class="mt-5">Military Talk</a>
            </li>
          </ul>
          <a href="../m_c.html#register" class="mt-5"
            ><p class="mt-4 text-center">Register</p></a
          >
        </div>

        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text6">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/5.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-4">
            <li>
              <a href="../religions.html#register" class="mt-5"
                >Religions Tweet</a
              >
            </li>
            <li>
              <a href="../religions.html#register" class="mt-5"
                >Religions Directory</a
              >
            </li>
            <li>
              <a href="../religions.html#register" class="mt-5"
                >Religions Talk</a
              >
            </li>
          </ul>
          <a href="../religions.html#register" class="mt-5"
            ><p class="mt-4 text-center">Register</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text11">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/6.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-4">
            <li>
              <a href="../entertainers.html#register" class="mt-5"
                >Entertainers Tweet</a
              >
            </li>
            <li>
              <a href="../entertainers.html#register" class="mt-5"
                >Entertainers Directory</a
              >
            </li>
            <li>
              <a href="../entertainers.html#register" class="mt-5"
                >Entertainers Talk</a
              >
            </li>
          </ul>
          <a href="../entertainers.html#register" class="mt-5"
            ><p class="mt-4 text-center">Register</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text9">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/7.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-4">
            <li>
              <a href="../nubians.html#register" class="mt-5">Nubians Tweet</a>
            </li>
            <li>
              <a href="../nubians.html#register" class="mt-5"
                >Nubians Directory</a
              >
            </li>
            <li>
              <a href="../nubians.html#register" class="mt-5">Nubians Talk</a>
            </li>
          </ul>
          <a href="../nubians.html#register" class="mt-5"
            ><p class="mt-4 text-center">Register</p></a
          >
        </div>

        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text12">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/8.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-4">
            <li>
              <a href="../hispanics.html#register" class="mt-5"
                >Hispanics Tweet</a
              >
            </li>
            <li>
              <a href="../hispanics.html#register" class="mt-5"
                >Hispanics Directory</a
              >
            </li>
            <li>
              <a href="../hispanics.html#register" class="mt-5"
                >Hispanics Talk</a
              >
            </li>
          </ul>
          <a href="../hispanics.html#register" class="mt-5"
            ><p class="mt-4 text-center">Register</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text8">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/9.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-4">
            <li>
              <a href="../hb.html#register" class="mt-5">Hair & Beauty Tweet</a>
            </li>
            <li>
              <a href="../hb.html#register" class="mt-5"
                >Hair & Beauty Directory</a
              >
            </li>
            <li>
              <a href="../hb.html#register" class="mt-5">Hair & Beauty Talk</a>
            </li>
          </ul>
          <a href="../hb.html#register" class="mt-5"
            ><p class="mt-4 text-center">Register</p></a
          >
        </div>

        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text10">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/10.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-4">
            <li>
              <a href="../alt.html#register" class="mt-5">Athletes Tweet</a>
            </li>
            <li>
              <a href="../alt.html#register" class="mt-5">Athletes Directory</a>
            </li>
            <li>
              <a href="../alt.html#register" class="mt-5">Athletes Talk</a>
            </li>
          </ul>
          <a href="../alt.html#register" class="mt-5"
            ><p class="mt-4 text-center">Register</p></a
          >
        </div>

        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text13">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/11.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-4">
            <li>
              <a href="../college.html#register" class="mt-5">Colleges Tweet</a>
            </li>
            <li>
              <a href="../college.html#register" class="mt-5"
                >Colleges Directory</a
              >
            </li>
            <li>
              <a href="../college.html#register" class="mt-5">Colleges Talk</a>
            </li>
          </ul>
          <a href="../college.html#register" class="mt-5"
            ><p class="mt-4 text-center">Register</p></a
          >
        </div>

        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/12.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-4">
            <li>
              <a href="../school.html#register" class="mt-5">Students Tweet</a>
            </li>
            <li>
              <a href="../school.html#register" class="mt-5"
                >Students Directory</a
              >
            </li>
            <li>
              <a href="../school.html#register" class="mt-5">Students Talk</a>
            </li>
          </ul>
          <a href="../school.html#register"
            ><p class="mt-4 text-center">Register</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/13.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-4">
            <li>
              <a href="../hbcu.html#register" class="mt-5">HCBU’S Tweet</a>
            </li>
            <li>
              <a href="../hbcu.html#register" class="mt-5">HCBU’S Directory</a>
            </li>
            <li>
              <a href="../hbcu.html#register" class="mt-5">HCBU’S Talk</a>
            </li>
          </ul>
          <a href="../hbcu.html#register" class="mt-5"
            ><p class="mt-4 text-center">Register</p></a
          >
        </div>
      </div>
    </div>
  </div>
  <section id="content-1">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="c1">
            <h3>
              THIS IS UMYO'S COMMUNITY MEMBERSHIP NETWORK THAT IS RUN AND OWNED
              BY COMMUNITY. MEMBERS BOTH SUPPORT BUSINESSES AND ARE SUPPORTED BY
              THEM IN RETURN.
            </h3>
            <p>Register Here</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="sc">
    <div class="container-fluid">
      <h2>COMMUNITY NETWORK REGISTRATION</h2>
    </div>
  </section>
  <div class="container-fluid featurs pt-3 mt-3">
    <div class="container">
      <div class="row g-4">
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/14.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/15.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/16.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/17.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/18.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/19.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/20.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/21.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/22.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/23.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/24.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/25.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/26.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/27.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/28.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/29.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/30.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
        <div class="col-lg-4 col-md-6 mt-5 px-5" id="text7">
          <div class="position-relative fruite-item">
            <div class="fruite-img">
              <div class="videos__wrapper">
                <div class="videos__wrapper--video2">
                  <img src="assets/img/network/31.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="mt-5"
            ><p class="mt-4 text-center">Register Here</p></a
          >
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Featurs Section End -->
<!-- product Start -->
<section id="card_section">
  <div class="container-fluid service py-2">
    <h1 class="text-center my-5">COMMUNITY BRANDING</h1>
    <div class="container py-5">
      <div class="row g-4">
        <div class="col-md-12 col-lg-8">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <a href="#">
                <img
                  src="assets/img/network/attorneys.jpg"
                  class="img-fluid mb-3"
                />
                <!-- <h5 class="text-center text-dark">Religious Entities</h5> -->
              </a>
            </div>
            <div class="col-lg-4 col-md-6">
              <a href="#">
                <img src="assets/img/network/nl.jpg" class="img-fluid mb-3" />
                <!-- <h5 class="text-center text-dark">Religious Entities</h5> -->
              </a>
            </div>
            <div class="col-lg-4 col-md-6">
              <a href="#">
                <img
                  src="assets/img/network/sports.jpeg"
                  class="img-fluid mb-3"
                />
                <!-- <h5 class="text-dark text-center">Sports Athletes</h5> -->
              </a>
            </div>

            <div class="col-lg-4 col-md-6">
              <a href="#">
                <img
                  src="assets/img/network/aaog/colleges.png"
                  class="img-fluid mb-3"
                />
              </a>
            </div>
            <div class="col-lg-4 col-md-6">
              <a href="#">
                <img
                  src="assets/img/network/aaog/entertainment.jpg"
                  class="img-fluid mb-3"
                />
                <!-- <h5 class="text-dark text-center">Nightlife and Clubs</h5> -->
              </a>
            </div>
            <div class="col-lg-4 col-md-6">
              <a href="#">
                <img
                  src="assets/img/network/aaog/hispanics.png"
                  class="img-fluid mb-3"
                />
                <!-- <h5 class="text-dark text-center">Realtors & Homes</h5> -->
              </a>
            </div>
            <div class="col-lg-4 col-md-6">
              <a href="#">
                <img
                  src="assets/img/network/aaog/restaurants.png"
                  class="img-fluid mb-3"
                />
              </a>
            </div>
            <div class="col-lg-4 col-md-6">
              <a href="#">
                <img
                  src="assets/img/network/hotel.jpg"
                  class="img-fluid mb-3"
                />
              </a>
            </div>
            <div class="col-lg-4 col-md-6">
              <a href="#">
                <img
                  src="assets/img/network/stylists.png"
                  class="img-fluid mb-3"
                />
                <!-- <h5 class="text-dark text-center">Fraternities and Sororities</h5> -->
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="pro_c">
            <h1>Grab a UMYO Network</h1>
            <p>Choose from our variety of products designed to fit...</p>
            <a href="#">
              <button class="btn btn-info border-0 text-white mt-3 py-4 px-5">
                Shop All Products
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- product End -->
</section>

<!-- Bestsaler Product Start -->

<!-- Footer Start -->
<section id="footer">
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <img src="assets/img/network/logof.png" class="img-fluid logo" />
        </div>
        <div class="col-lg-5 d-none d-lg-block"></div>
        <div class="col-lg-4">
          <p>#75-2895137</p>
          <!-- <p>© 2022 AAOG. All Rights Reserved.</p> -->
        </div>
        <div class="col-lg-12">
          <!-- <p>#75-2895137</p> -->
          <p class="copy">© 2022 AAOG. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </div>
</section>
