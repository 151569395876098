export const environment = {
  production: true,
  apiUrl: "https://umyostylist.com/api_umyocards/public/api/"
};
// export const environment = {
//   production: true,
//   apiUrl: "http://127.0.0.1:8000/api/"
// };


